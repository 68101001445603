export default {
  users: {
    add: {
      success: {
        color: "success",
        text: "Administrateur ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout de l'adminitrateur",
        title: "Ajout"
      }
    },
    update: {
      success: {
        color: "success",
        text: "Administrateur modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification de l'adminitrateur",
        title: "Mis à jour"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Administrateur  supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression de l'adminitrateur",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Administrateurs  supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des adminitrateurs",
        title: "Suppression"
      }
    },
    active: {
      success: {
        color: "success",
        text: "Compte activé avec succès",
        title: "Activation"
      }
    },
    desactive: {
      success: {
        color: "success",
        text: " Compte désactivé avec succès",
        title: "Désactivation"
      }
    },
    reinitialise: {
      success: {
        color: "success",
        text: "Réinitialisé avec succès",
        title: "Mot de passe"
      },
      error: {
        color: "danger",
        text: "Administrateur n'existe pas",
        title: "Mot de passe"
      }
    },
    mdp: {
      success: {
        color: "success",
        text: "Mot de passe a été changé avec succès",
        title: "Changement du mot de passe"
      },
      error: {
        color: "danger",
        text: "Ancien mot de passe incorrect",
        title: "Changement du mot de passe"
      }
    },
    updateUser: {
      success: {
        color: "success",
        text: "Les informations ont été mises à jour avec succès",
        title: "Mise à jour profil"
      },
      error: {
        color: "danger",
        text: "Une erreur s'est produite",
        title: "Mise à jour profil"
      }
    },
    updateAvatar: {
      success: {
        color: "success",
        text: "Image a été téléchargée avec succès",
        title: "Mise à jour avatar"
      },
      error: {
        color: "danger",
        text: "Une erreur s'est produite",
        title: "Mise à jour profil"
      }
    },
    notFound: {
      error: {
        color: "danger",
        text: "Le nom d'utilisateur n'existe pas",
        title: "Utilisateur"
      },
    },
    nameExist: {
      error: {
        color: "danger",
        text: "Nom d'utilisateur existe déjà",
        title: "Utilisateur"
      },
    },
    mailError: {
      error: {
        color: "danger",
        text: "Impossible d'envoyer le mail à l'adresse",
        title: "Mail"
      },
    },
  },
  broker: {
    add: {
      success: {
        color: "success",
        text: "Intermédiaire ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout de l'intermédiaire",
        title: "Ajout"
      },
    },
    update: {
      success: {
        color: "success",
        text: "Intermédiaire modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification de l'intermédiaire",
        title: "Mis à jour"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Intermédiaire  supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression de l'intermédiaire",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Intermédiaires  supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des intermédiaires",
        title: "Suppression"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Logo a été ajouté avec succès",
        title: "Logo"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du logo",
        title: "Logo"
      }
    },
    active: {
      success: {
        color: "success",
        text: "Compte activé avec succès",
        title: "Activation"
      }
    },
    desactive: {
      success: {
        color: "success",
        text: "Compte désactivé avec succès",
        title: "Désactivation"
      }
    },
    reinitialise: {
      success: {
        color: "success",
        text: "Réinitialisé avec succès",
        title: "Mot de passe"
      },
      error: {
        color: "danger",
        text: "Intermédiaire n'existe pas",
        title: "Mot de passe"
      }
    },
    notFound: {
      error: {
        color: "danger",
        text: "Le nom d'utilisateur n'existe pas",
        title: "Utilisateur"
      },
    },
    nameExist: {
      error: {
        color: "danger",
        text: "Nom d'utilisateur existe déjà",
        title: "Utilisateur"
      },
    },
    mailError: {
      error: {
        color: "danger",
        text: "Impossible d'envoyer le mail à l'adresse",
        title: "Mail"
      },
    },
  }, 
  mailing: {
    add: {
      success: {
        color: "success",
        text: "Mailing ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du mailing",
        title: "Ajout"
      }
    },
    update: {
      success: {
        color: "success",
        text: "Mailing modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du mailing",
        title: "Mis à jour"
      }
    },
    record: {
      success: {
        color: "success",
        text: "Mailing envoyé avec succès",
        title: "Envoie"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie du mailing",
        title: "Envoie"
      }
    },
    planning: {
      success: {
        color: "success",
        text: "Mailing planifié avec succès",
        title: "Planification"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la planification du mailing",
        title: "Planification"
      }
    },
    duplicate: {
      success: {
        color: "success",
        text: "Mailing dupliquer avec succès",
        title: "Planification"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la duplication du mailing",
        title: "Planification"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Mailing  supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du mailing",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Mailings supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des mailing",
        title: "Suppression"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Fichier a été ajouté avec succès",
        title: "Fichier"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du fichier",
        title: "Fichier"
      }
    },
    active: {
      success: {
        color: "success",
        text: "Compte activé avec succès",
        title: "Activation"
      }
    },
    desactive: {
      success: {
        color: "success",
        text: "Compte désactivé avec succès",
        title: "Désactivation"
      }
    },
    reinitialise: {
      success: {
        color: "success",
        text: "Réinitialisé avec succès",
        title: "Mot de passe"
      },
      error: {
        color: "danger",
        text: "Intermédiaire n'existe pas",
        title: "Mot de passe"
      }
    }
  },
  mail_received: {
    show: true,
    create: true,
    send: true,
    all: true
  },
  mail_client: {
    file: {
      success: {
        color: "success",
        text: "Fichier ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du Fichier",
        title: "Ajout"
      }
    },
    record: {
      success: {
        color: "success",
        text: "Mail envoyé avec succès",
        title: "Envoie"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie du mail",
        title: "Envoie"
      }
    }
  },
  ticket: {
    add: {
      success: {
        color: "success",
        text: "Ticket ajouté avec succes",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: " Erreur lors de l'ajout du ticket'",
        title: "Mis à jour"
      }
    },
    close: {
      success: {
        color: "success",
        text: "Ticket fermé avec succes",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: " Erreur lors de la fermeture du ticket",
        title: "Mis à jour"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Fichier ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du Fichier",
        title: "Ajout"
      }
    },
    comment: {
      success: {
        color: "success",
        text: "Commentaire envoyé avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie du commentaire",
        title: "Ajout"
      }
    },
    commentUpdate: {
      success: {
        color: "success",
        text: "Commentaire modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du commentaire",
        title: "Mis à jour"
      }
    },
    commentDelete: {
      success: {
        color: "success",
        text: "Commentaire supprimé avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du commentaire",
        title: "Ajout"
      }
    },
    off: {
      success: {
        color: "success",
        text: "Ticket fermé avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la fermeture du ticket",
        title: "Mis à jour"
      }
    }
  },
  ongoing_project: {
    relance: {
      success: {
        color: "success",
        text: "Relance envoyée avec succes",
        title: "Relancer"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie de la relance'",
        title: "Relancer"
      }
    },
    close: {
      success: {
        color: "success",
        text: "Projet fermé avec succes",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: " Erreur lors de la fermeture du projet",
        title: "Mis à jour"
      }
    },
    update: {
      success: {
        color: "success",
        text: "Projet fermé avec succes",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la fermeture du projet",
        title: "Mis à jour"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Projet supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du projet",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "projets supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des projets",
        title: "Suppression"
      }
    }
  },
  slips: {
    add: {
      success: {
        color: "success",
        text: "Bordereau ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du bordereau",
        title: "Ajout"
      }
    },
    update: {
      success: {
        color: "success",
        text: "Bordereau modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du bordereau",
        title: "Mis à jour"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Bordereau  supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du bordereau",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Bordereaux  supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des bordereaux",
        title: "Suppression"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Fichier ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du Fichier",
        title: "Ajout"
      }
    }
  },
  customer_document: {
    add: {
      success: {
        color: "success",
        text: "Document ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du document",
        title: "Ajout"
      }
    },
    update: {
      success: {
        color: "success",
        text: "Document modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du document",
        title: "Mis à jour"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Document  supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du document",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Documents supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des documents",
        title: "Suppression"
      }
    }
  },
  customer_account: {
    update: {
      success: {
        color: "success",
        text: "Client modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du client",
        title: "Mis à jour"
      }
    },
    delete: {
      success: {
        color: "success",
        text: "Client supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du client",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Clients supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des clients",
        title: "Suppression"
      }
    },

    active: {
      success: {
        color: "success",
        text: "Compte activé avec succès",
        title: "Activation"
      }
    },
    desactive: {
      success: {
        color: "success",
        text: " Compte désactivé avec succès",
        title: "Désactivation"
      }
    },
    reinitialise: {
      success: {
        color: "success",
        text: "Réinitialisé avec succès",
        title: "Mot de passe"
      },
      error: {
        color: "danger",
        text: "Client n'existe pas",
        title: "Mot de passe"
      }
    },
    validity: {
      success: {
        color: "success",
        text: "Date de validité mise à jour avec succès",
        title: "Date validité"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la mis à jour date validité",
        title: "Date validité"
      }
    },
    pictoTarifs: {
      success: {
        color: "success",
        text: "Les données mise à jour avec succès",
        title: "Date validité"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la mis à jour des données",
        title: "Date validité"
      }
    }
  },
  subscription: {
    sendNotice: {
      success: {
        color: "success",
        text: "Effectué avec succès",
        title: "Envoi d'avis"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie d'avis",
        title: "Envoi d'avis"
      }
    },
    ChangeIntermediary: {
      success: {
        color: "success",
        text: "Intermédiaire changé avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors du changement de l'intermédiaire",
        title: "Mis à jour"
      }
    },

    delete: {
      success: {
        color: "success",
        text: "Souscription supprimé avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression de la souscription",
        title: "Suppression"
      }
    },
    multipleDeletion: {
      success: {
        color: "success",
        text: "Souscriptions  supprimés avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression des souscriptions",
        title: "Suppression"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Fichier ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du Fichier",
        title: "Ajout"
      }
    },
    /* commentaire */

    comment: {
      success: {
        color: "success",
        text: "Commentaire envoyé avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie du commentaire",
        title: "Ajout"
      }
    },
    commentUpdate: {
      success: {
        color: "success",
        text: "Commentaire modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification du commentaire",
        title: "Mis à jour"
      }
    },
    commentDelete: {
      success: {
        color: "success",
        text: "Commentaire supprimé avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression du commentaire",
        title: "Ajout"
      }
    },
    /* End commentaire */
    addClient: {
      success: {
        color: "success",
        text: "Client ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du client",
        title: "Ajout"
      }
    },
    enablePolice: {
      success: {
        color: "success",
        text: "Police activée avec succès",
        title: "Activation"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'activation de la police",
        title: "Activation"
      }
    },
    payement: {
      success: {
        color: "success",
        text: "Paiement a été effectué avec succès",
        title: "Paiement"
      },
      error: {
        color: "danger",
        text: "Erreur lors du paiement",
        title: "Paiement"
      }
    },
    cancelPayment: {
      success: {
        color: "success",
        text: "Paiement annulé avec succès",
        title: "Paiement"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'annulation du paiement",
        title: "Paiement"
      }
    },
    /* Avenant */
    addAvenant: {
      success: {
        color: "success",
        text: "Avenant ajouté avec succès",
        title: "Ajout"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout de l'avenant",
        title: "Ajout"
      }
    },
    updateAvenant: {
      success: {
        color: "success",
        text: "Avenant modifié avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification de l'avenant",
        title: "Mis à jour"
      }
    },
    updateQuittance: {
      success: {
        color: "success",
        text: "Quittance modifiée avec succès",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la modification de la quittance",
        title: "Mis à jour"
      }
    },
    deleteAvenant: {
      success: {
        color: "success",
        text: "Avenant supprimer avec succès",
        title: "Suppression"
      },
      error: {
        color: "danger",
        text: "Erreur lors de la suppression de l'avenant",
        title: "Suppression"
      }
    },
    activeAvenant: {
      success: {
        color: "success",
        text: "Avenant activé avec succès",
        title: "Activation"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'activation de l'avenant",
        title: "Activation"
      }
    },
    /* End Avenant */

  },

  mails: {
    add: {
      success: {
        color: "success",
        text: "Mail envoyé avec succès",
        title: "Envoie"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'aenvoie du mail",
        title: "Envoie"
      }
    },
    file: {
      success: {
        color: "success",
        text: "Fichier a été ajouté avec succès",
        title: "Fichier"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du fichier",
        title: "Fichier"
      }
    },
  }
};
